



















import { Component, Vue } from 'vue-property-decorator'

import ProgressList from '@/components/shared/ProgressList.vue'
import ThankYou from '@/components/done/ThankYou.vue'

import { getModule } from 'vuex-module-decorators'
import User from '@/store/modules/user'
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'

import Store from '@/store/index'

// create tyepsafe accessor using getModule to access state of the User and Configuration from the store.
const user = getModule(User, Store)
const recording = getModule(Recording, Store)
const recordingPart = getModule(RecordingPart, Store)

@Component({
  components: {
    ProgressList,
    ThankYou
  }
})
export default class Done extends Vue {
  mounted () {
    user.reset()
    recording.reset()
    recordingPart.reset()
  }
}

